import { useState, useEffect } from 'react';
import { extractMatchData, fetchCurrentScores } from '../utils/scoreUtils';

const useMatchScoreData = (match) => {
  const [matchScoreData, setMatchScoreData] = useState(null);

  useEffect(() => {
    if (match.match.status === 'LOCKED') {
      const fetchScores = async () => {
        const matchData = await fetchCurrentScores();
        const scoreData = extractMatchData(matchData, match.match.name);
        setMatchScoreData(scoreData);
      };
      fetchScores();
    }
  }, [match.match.status, match.match.name]);

  return matchScoreData;
};

export default useMatchScoreData;