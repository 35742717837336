import React from 'react';

const LoadingSpinner = () => {
  return (
    <div title="Cargando..." data-testid="loading-spinner" className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <svg data-testid="loading-spinner-svg" width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
        <circle
          data-testid="loading-spinner-circle"
          cx="40"
          cy="40"
          r="32"
          fill="none"
          stroke="#3B82F6"
          strokeWidth="8"
          strokeLinecap="round"
          strokeDasharray="150.79 50.26"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="1s"
            repeatCount="indefinite"
            from="0 40 40"
            to="360 40 40"
          />
        </circle>
      </svg>
    </div>
  );
};

export default LoadingSpinner;
