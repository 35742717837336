import { useState, useEffect, useCallback } from 'react';
import { extractTeamData, fetchDataGameResults, getCurrentYear, addSpreadCoverage } from '../utils/scoreUtils';

export const useMainPageHooks = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [sortDescending, setSortDescending] = useState(true);
  const [showMarquee, setShowMarquee] = useState(false);
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode) {
      return JSON.parse(savedMode) || false;
    }
    const mediaQuery = window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)') : null;
    return mediaQuery ? mediaQuery.matches : false;
  });
  const [isMosaicView, setIsMosaicView] = useState(false);
  const [year, setYear] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('year') || getCurrentYear().toString();
  });
  const [gameData, setGameData] = useState([]);
  const [isRealTime, setIsRealTime] = useState(() => {
    const savedMode = localStorage.getItem('realTime');
    if (savedMode) {
      try {
        return JSON.parse(savedMode) || false;
      } catch (error) {
        return false;
      }
    }
  });

  const toggleSort = useCallback(() => setSortDescending(prev => !prev), []);
  const toggleDarkMode = useCallback(() => setDarkMode(prev => !prev), []);
  const toggleView = useCallback(() => setIsMosaicView(prev => !prev), []);
  const toggleRealTime = useCallback(() => setIsRealTime(prev => !prev), []);
  const toggleShowMarquee = useCallback(() => setShowMarquee(prev => !prev), []);

  useEffect(() => {
    const mediaQuery = window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)') : null;

    if (mediaQuery) {
      const handleChange = (e) => setDarkMode(e.matches);

      if (mediaQuery.addEventListener) {
        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    localStorage.setItem('realTime', JSON.stringify(isRealTime));
  }, [isRealTime]);

  useEffect(() => {
    fetchDataGameResults()
      .then(extractTeamData)
      .then(addSpreadCoverage)
      .then(setGameData)
      .catch(error => console.error('Failed to fetch game data:', error));
  }, []);

  return {
    searchQuery,
    setSearchQuery,
    sortDescending,
    toggleSort,
    showMarquee,
    darkMode,
    toggleDarkMode,
    isMosaicView,
    toggleView,
    year,
    setYear,
    gameData,
    isRealTime,
    toggleRealTime,
    toggleShowMarquee
  };
};
