import React from 'react';

function SortButton({ sortDescending, onToggle }) {
  return (
    <button
      onClick={onToggle}
      className={`p-2 rounded-full focus:outline-none ${
        !sortDescending ? 'bg-sky-900 dark:bg-sky-700' : 'bg-gray-200 dark:bg-gray-600'
      }`}
      aria-label={sortDescending ? "Sort ascending" : "Sort descending"}
    >
      {sortDescending ? (
        <svg className="w-6 h-6 text-gray-800 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"></path>
        </svg>
      ) : (
        <svg className="w-6 h-6 text-gray-200 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"></path>
        </svg>
      )}
    </button>
  );
}

export default SortButton;
