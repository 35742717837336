import { useState, useEffect, useCallback, useRef } from 'react';
import { fetchDataGameResults, extractTeamData, addSpreadCoverage } from '../utils/scoreUtils';

const useRefreshedData = (initialData) => {
  const [data, setData] = useState(initialData);
  const timeoutIdRef = useRef(null);
  const isFetchingRef = useRef(false);
  const mountedRef = useRef(true);

  const hasLockedMatch = useCallback((matchData) => {
    return matchData.some(match => match.match.status === 'LOCKED');
  }, []);

  const fetchData = useCallback(async () => {
    if (isFetchingRef.current || !mountedRef.current) return;
    isFetchingRef.current = true;

    console.log('Fetching data:', new Date().toLocaleString());
    try {
      const rawData = await fetchDataGameResults();
      const extractedData = extractTeamData(rawData);
      const processedData = addSpreadCoverage(extractedData);
      if (mountedRef.current) {
        setData(processedData);

        if (hasLockedMatch(processedData)) {
          console.log('Locked matches found, scheduling next fetch');
          timeoutIdRef.current = setTimeout(fetchData, 10000);
          console.log('Timeout set:', timeoutIdRef.current);
        } else {
          console.log('No locked matches, stopping fetch');
          timeoutIdRef.current = null;
        }
      }
    } catch (error) {
      console.error('Error fetching refreshed data:', error);
    } finally {
      isFetchingRef.current = false;
    }
  }, [hasLockedMatch]);

  useEffect(() => {
    mountedRef.current = true;
    
    if (hasLockedMatch(data) && !timeoutIdRef.current) {
      console.log('Starting fetch process');
      fetchData();
    }

    return () => {
      mountedRef.current = false;
      if (timeoutIdRef.current) {
        console.log('Clearing data fetch timeout on unmount');
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
};

export default useRefreshedData;