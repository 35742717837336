import React from 'react';
import useDisplayWidth from '../../hooks/useDisplayWidth';
import useMemoizedItems from '../../hooks/useMemoizedItems';
import useRefreshedData from '../../hooks/useRefreshedData';

const Marquee = ({ initialData }) => {
  const refreshedData = useRefreshedData(initialData);
  useDisplayWidth(refreshedData);
  const items = useMemoizedItems(refreshedData);

  return (
    <div className="relative w-full overflow-hidden">
      <div className="flex whitespace-nowrap animate-marquee">
        {items}
      </div>
      <div className="absolute top-0 flex whitespace-nowrap animate-marquee2">
        {items}
      </div>
    </div>
  );
};

export default Marquee;