import React from 'react';
import useMatchScoreData from '../../hooks/useMatchScoreData';
import useWinningTeam from '../../hooks/useWinningTeam';
import Item from './Item';

const MarqueeItem = ({ match }) => {
  const isComplete = match.match.status === 'COMPLETE';
  const matchScoreData = useMatchScoreData(match);

  if (matchScoreData) {
    match.outcomes[1].score = matchScoreData.competitions[0].competitors[0].score;
    match.outcomes[0].score = matchScoreData.competitions[0].competitors[1].score;
  }

  const winningTeam = useWinningTeam(match, isComplete);

  return (
    <div className="inline-flex flex-col flex-shrink-0 shadow-md dark:shadow-gray-600/50 bg-white dark:bg-gray-800 rounded-lg p-4 mb-4 mt-4 mx-2 items-center">
      <a href={match.match.liveScoreURL} target="_blank" rel="noopener noreferrer">
        <div className="flex items-center">
          <Item
            outcome={match.outcomes[0]}
            isLeft={true}
            isComplete={isComplete}
            winningTeam={winningTeam}
          />
          <div className="text-2xl font-bold text-black dark:text-gray-200 m-1">@</div>
          <Item
            outcome={match.outcomes[1]}
            isLeft={false}
            isComplete={isComplete}
            winningTeam={winningTeam}
          />
        </div>
      </a>
    </div>
  );
};

export default MarqueeItem;