import React from 'react';

const ViewToggle = ({ isMosaicView, onToggle }) => {
  return (
    <button
      onClick={onToggle}
      className={`p-2 rounded-full focus:outline-none ${
        isMosaicView ? 'bg-sky-900 dark:bg-sky-700' : 'bg-gray-200 dark:bg-gray-600'
      }`}
      aria-label={isMosaicView ? "Switch to List View" : "Switch to Mosaic View"}
    >
      {isMosaicView ? (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-200 dark:text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-800 dark:text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg>
      )}
    </button>
  );
};

export default ViewToggle;
