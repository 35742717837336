const TeamLogo = ({ logo, team }) => {
  const logoPath = logo.split('/').slice(-3).join('/');
  const newLogoUrl = `https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${logoPath.split('/').pop()}&scale=crop&cquality=100&location=origin&w=96&h=96`;

  return (
    <div className="flex flex-col flex-top items-center">
      <img
        src={newLogoUrl}
        alt={`${team} logo`}
        className="w-16 h-16 mobile:w-6 mobile:h-6 bg-gray-100 rounded-lg shadow-md dark:shadow-gray-600/50"
      />
    </div>
  );
};

export default TeamLogo;