import { useMemo } from 'react';

const useWinningTeam = (match, isComplete) => {
  return useMemo(() => {
    if (!isComplete) return null;
    const [team1, team2] = match.outcomes;
    return team1.score + parseFloat(team1.spread) > team2.score ? team1.team : team2.team;
  }, [match, isComplete]);
};

export default useWinningTeam;