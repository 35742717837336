import TeamLogo from './TeamLogo';
import TeamInfo from './TeamInfo';

const Item = ({ outcome, isLeft, isComplete, winningTeam }) => {
  const isWinner = isComplete && outcome.team === winningTeam;
  const scoreClass = `text-2xl font-bold ${isWinner ? 'text-green-800 dark:text-green-400' : 'dark:text-white'}`;

  return (
    <div className="bg-white dark:bg-gray-800 flex flex-col items-center">
      <div className="flex flex-row items-center justify-center space-x-4 m-1">
        {isLeft ? (
          <>
            <TeamLogo logo={outcome.logo} team={outcome.team} />
            <TeamInfo outcome={outcome} scoreClass={scoreClass} />
          </>
        ) : (
          <>
            <TeamInfo outcome={outcome} scoreClass={scoreClass} isRight />
            <TeamLogo logo={outcome.logo} team={outcome.team} />
          </>
        )}
      </div>
    </div>
  );
};

export default Item;