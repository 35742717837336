import { useState, useEffect } from 'react';

const useDisplayWidth = (data) => {
  const [displayWidth, setDisplayWidth] = useState(window.innerWidth);

  useEffect(() => {
    function updateMarqueeTransform() {
      const transformPercentage = (data.length + 2) * 43200 / displayWidth;
      document.documentElement.style.setProperty('--marquee-transform', transformPercentage);
    }

    updateMarqueeTransform();

    function handleResize() {
      setDisplayWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [data, displayWidth]);

  return displayWidth;
};

export default useDisplayWidth;