import React, { Suspense, lazy } from 'react';
import { useMainPageHooks } from '../hooks/useMainPageHooks';
import Marquee from '../components/marquee/Marquee';
import SearchBar from '../components/SearchBar';
import SortButton from '../components/toggles/SortButton';
import DarkModeToggle from '../components/toggles/DarkModeToggle';
import ViewToggle from '../components/toggles/ViewToggle';
import YearPillsRow from '../components/YearPillsRow';
import MarqueeToggle from '../components/toggles/MarqueeToggle';
import RealTimeToggle from '../components/toggles/RealTimeToggle';
import LoadingSpinner from '../components/LoadingSpinner';

const ScoreTableLazy = lazy(() => import('../components/ScoreTable'));

function MainPage() {
  const {
    searchQuery,
    setSearchQuery,
    sortDescending,
    toggleSort,
    showMarquee,
    toggleShowMarquee,
    darkMode,
    toggleDarkMode,
    isMosaicView,
    toggleView,
    year,
    setYear,
    gameData,
    isRealTime,
    toggleRealTime
  } = useMainPageHooks();


  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-300">
      {showMarquee && <Marquee initialData={gameData} />}
      <div className={`container mx-auto px-4 ${!showMarquee ? 'py-4' : ''}`}>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <div className="flex flex-col md:flex-row justify-items-center items-center justify-between mb-6">
            <div className="flex flex-row items-center mx-2 px-2">
              <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-2 md:mb-0">🏈 Resultados {year}</h1>
            </div>
            <div className="flex flex-row grid-flow-row grid-cols-4 md:grid-cols-5 mx-2 md:mx-0 px-2 md:px-0 space-x-4">
              <div className="hidden md:block">
                <ViewToggle isMosaicView={isMosaicView} onToggle={toggleView} />
              </div>
              <div className="justify-self-center">
                <SortButton sortDescending={sortDescending} onToggle={toggleSort} />
              </div>
              <div className="justify-self-center">
                <DarkModeToggle darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
              </div>
              <div className="justify-self-center">
                <MarqueeToggle showMarquee={showMarquee} onToggle={toggleShowMarquee} />
              </div>
              <div className="justify-self-center pr-4 md:pr-0">
                <RealTimeToggle isRealTime={isRealTime} onToggle={toggleRealTime} />
              </div>
            </div>
          </div>
          <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          <YearPillsRow setYear={setYear} year={year} />
          <Suspense fallback={<LoadingSpinner />}>
            <ScoreTableLazy
              searchQuery={searchQuery}
              sortDescending={sortDescending}
              isMosaicView={isMosaicView}
              year={year}
              gameScoreData={gameData}
              isRealTime={isRealTime}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
