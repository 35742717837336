import React from 'react';

const RealTimeToggle = ({ isRealTime, onToggle }) => {
  return (
    <button
      onClick={onToggle}
      className={`p-2 rounded-full focus:outline-none ${
        isRealTime ? 'bg-sky-900 dark:bg-sky-700' : 'bg-gray-200 dark:bg-gray-600'
      }`}
      aria-label={isRealTime ? "Switch to Static Results" : "Switch to Real-Time Results"}
    >
      {isRealTime ? (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-100 dark:text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-800 dark:text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      )}
    </button>
  );
};

export default RealTimeToggle;
