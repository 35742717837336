import React from 'react';
import { getYearsFrom2022 } from '../utils/scoreUtils';

function YearPillsRow({ setYear, year }) {
  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('year', selectedYear);
    window.history.pushState({}, '', newUrl);
  };

  return (
    <div className="flex space-x-2 mt-4 mb-6">
      {getYearsFrom2022().map((yearOption) => (
        <button
          key={yearOption}
          onClick={() => handleYearChange(yearOption)}
          className={`px-4 py-2 rounded-full ${
            year.toString() === yearOption.toString()
              ? 'bg-sky-900 text-gray-100 font-black dark:bg-sky-700'
              : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-100'
          } transition-colors duration-200`}
        >
          {yearOption}
        </button>
      ))}
    </div>
  );
}

export default YearPillsRow;